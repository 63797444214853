import { AxiosResponse } from "axios";
import { appSuccessToast } from "../components/common";
import axiosInstance from "../utils/axios";
import { IOvertimeAssigment } from "./types/overtimeAssigments";
import { IAssignmentsResponse } from "./types/table";
import apiService from "./api.service";
import {
  IAssignmentDetails,
  ICreateRealizationRequest,
  IFiltersData,
} from "./types/overtimeAssigments";
import { assignmentStatus, localStorageAccessorEnum, userRoleEnum} from "../utils/enums";

const pathPrefix = `${process.env.REACT_APP_SERVER_URL}/api`;

class OvertimeAssigments {
  addAssigment(assigment: IOvertimeAssigment): Promise<AxiosResponse> {
    return axiosInstance
      .post(`${pathPrefix}/overtime-assignments`, assigment)
      .then((response) => {
        appSuccessToast("Formularz został wysłany poprawnie");
        return response;
      });
  }

  getAssignments(skip: number, filters?: IFiltersData) {
    const rows = 10;
    const params = [];
    const {employeeId, dateFrom, dateTo, assignmentStatus} = filters ?? {};
    const asAdmin = localStorage.getItem("workContext") === userRoleEnum.Admin ? 'true' : 'false'
    
    if ( employeeId ) params.push({key: 'employeeId', value: employeeId});
    if ( dateFrom ) params.push({key: 'dateFrom', value: dateFrom});
    if ( dateTo ) params.push({key: 'dateTo', value: dateTo});
    if ( assignmentStatus ) params.push({key: 'status', value: assignmentStatus});
    params.push({key: 'asAdmin', value: asAdmin});

    let path = `${pathPrefix}/overtime-assignments/list?skip=${skip}&rows=${rows}`;
    if (params.length > 0) {
      path = apiService.setPathParams(path, params);
    }

    return axiosInstance
      .get<IAssignmentsResponse>(path.toString())
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  getAssignmentDetails(id: string): Promise<IAssignmentDetails> {
    return axiosInstance
      .get<IAssignmentDetails>(
        `${pathPrefix}/overtime-assignments/${id}`
      )
      .then((response) => {
        return Promise.resolve(response.data);
      });
  }

  updateAssignment(
    status: assignmentStatus,
    rejectionDescription: string,
    id: string
  ) {
    return axiosInstance
      .put(`${pathPrefix}/overtime-assignments/${id}`, {
        status: status,
        rejectionDescription: rejectionDescription,
      })
      .then(() => {
        appSuccessToast("Status zlecenia został zaktualizowany");
        return Promise.resolve();
      });
  }

  realizeAssignment(requestBody: ICreateRealizationRequest): Promise<AxiosResponse> {
    return axiosInstance
      .post(
        `${pathPrefix}/overtime-assignments/${requestBody.id}/realizations`,
        {
          realizationDate: requestBody.realizationDate,
          duration: requestBody.duration,
          rejectionDescription: requestBody.rejectionDescription,
          urls: requestBody.urls,
        }
      )
      .then((response) => {
        appSuccessToast("Pomyślnie oznaczono jako zrealizowane");
        return response;
      });
  }

  deleteAssignment(assignmentId: string) {
    const asAdmin = localStorage.getItem("workContext") === userRoleEnum.Admin ? true : false
    return axiosInstance
      .delete(`${pathPrefix}/overtime-assignments/${assignmentId}?asAdmin=${asAdmin}`)
      .then(() => {
        appSuccessToast("Wpis został pomyślnie usunięty");
      })
  }

  editAssigment(assigment: IOvertimeAssigment, id: string): Promise<AxiosResponse> {
    return axiosInstance
      .put(`${pathPrefix}/overtime-assignments/edit/${id}`, assigment)
      .then((response) => {
        appSuccessToast("Formularz został wysłany poprawnie");
        return response;
      });
  }

  getStoredFilters(): IFiltersData {
    const storedFilters = localStorage.getItem(localStorageAccessorEnum.Filters);
    return storedFilters ? JSON.parse(storedFilters) : undefined;
  }
}

const overtimeAssigments = new OvertimeAssigments();
export default overtimeAssigments;
