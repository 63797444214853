import { Select } from "@fluentui/react-components";
import { userRoleEnum } from "../../../../utils/enums";
import { useContext } from "react";
import UserContext from "../../../../modules/AppRouter/UserContext";
import "./UserRoleSelect.css";
import { useHistory } from "react-router-dom";

interface IAppDialogProps {
    setWorkContext: React.Dispatch<React.SetStateAction<string | null>>;
    workContext: string | null;
}

const UserRoleSelect: React.FC<IAppDialogProps> = ({
    setWorkContext,
    workContext
}) => {
  const { userData, setUserData } = useContext(UserContext);
  const history = useHistory();

  const options = [
    { key: "admin-option", value: userRoleEnum.Admin, label: "Administrator" },
    { key: "employee-option", value: userRoleEnum.Employee, label: "Pracownik" }
  ];

  const filteredOptions = options.filter(option => userData.data.roles.includes(option.value));

  const handleRoleChange = async (roleChangeEvent: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRole = roleChangeEvent.target.value;
    localStorage.setItem("workContext", selectedRole);
    setWorkContext(selectedRole)
    
    let newUserData = { ...userData };
    newUserData.data.baseRole = selectedRole;
    setUserData(newUserData);

    history.push('/');
  };

  return <>
    <div className="select-role-container">
      <span className="role-label">Rola:</span>
      <Select 
        id={"user-role-select"} 
        value={workContext ?? userRoleEnum.Admin} 
        onChange={handleRoleChange}
      >
        {filteredOptions.map(option => (
          <option key={option.key} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </div>
  </>
};

export default UserRoleSelect;